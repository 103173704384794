.m_2 {
	margin-top: 25px;
}
.m_2_wrapper {
	font-style: normal;
	font-weight: 900;
	font-size: 20px;
	line-height: 40px;
	letter-spacing: 0.04em;
	color: #C52333;
	box-sizing: border-box;
	span {
		color: #0C990D;
		padding-left: 10px;
		padding-right: 10px;
	}
}



.marquee {
 width: 100%;
 margin: 0 auto;
 white-space: nowrap;
 overflow: hidden;
 box-sizing: border-box;
}

.marquee p {
 display: inline-block;
 padding-left: 100%;
 animation: marquee 30s linear infinite;
}
.m_2_dop {
	display: none;
	margin-top: 40px;
}


@keyframes marquee {
 0%   { transform: translate(-20%, 0); opacity: 0; }
 7% {
 	opacity: 1;
 }
 93% {
 	opacity: 1;
 }
 100% { transform: translate(-80%, 0); opacity: 0; }
}

@media screen and (max-width: 1300px){
    
}
@media screen and (max-width: 992px){
    .m_2 {
    	margin-top: 35px;
    }
    .m_2 {
    	display: none;
    }
    .m_2_dop {
    	display: block;
    }
    .m_2_dop_wrapper {
    	height: 50px;
    	overflow: hidden;
    	line-height: 30px;
    	font-style: normal;
    	font-weight: 900;
    	font-size: 32px;
    	line-height: 50px;
    	letter-spacing: .04em;
    	color: #c52333;
    	text-align: center;
    	box-sizing: border-box;
    	position: relative;
    	p {
    		position: absolute;
    		top: 0px;
    		left: 0px;
    		width: 100%;
    		height: 100%;
    		transform: translateY(-110%);
    	}
    	span {
    		color: #c52333 !important;
    	}
    	p:nth-child(even){
    		color: #0c990d;
    	}
    	p:nth-child(1) {
    		animation: move_1 8s linear 0s infinite; 
    	}
    	p:nth-child(2) {
    		animation: move_2 8s linear 0s infinite; 
    	}
    	p:nth-child(3) {
    		animation: move_3 8s linear 0s infinite; 
    	}
    	p:nth-child(4) {
    		animation: move_4 8s linear 0s infinite; 
    	}
    }
    @keyframes move_1 {
    	0% {
    		transform: translateY(-110%);
    	}
    	2% {
    		transform: translateY(0%);
    	}
    	23% {
            transform: translateY(0%);
    	}
    	25% {
    		transform: translateY(110%);
    	}
    	98% {
            transform: translateY(110%);
            opacity: 1;
    	}
    	99% {
    		transform: translateY(110%);
            opacity: 0;
    	}
    	100% {
    		transform: translateY(-110%);
    		opacity: 0;
    	}
    }
    @keyframes move_2 {
    	0% {
    		transform: translateY(-110%);
    	}
    	23% {
            transform: translateY(-110%);
    	}
    	25% {
    		transform: translateY(0%);
    	}
    	48% {
    		transform: translateY(0%);
    	}
    	50% {
    		transform: translateY(110%);
    	}
    	98% {
            transform: translateY(110%);
            opacity: 1;
    	}
    	99% {
    		transform: translateY(110%);
            opacity: 0;
    	}
    	100% {
    		transform: translateY(-110%);
    		opacity: 0;
    	}
    }
    @keyframes move_3 {
    	0% {
    		transform: translateY(-110%);
    	}
    	48% {
    		transform: translateY(-110%);
    	}
    	50% {
    		transform: translateY(0%);
    	}
    	73% {
    		transform: translateY(0%);
    	}
    	75% {
            transform: translateY(110%);
            opacity: 1;
    	}
    	99% {
    		transform: translateY(110%);
            opacity: 0;
    	}
    	100% {
    		transform: translateY(-110%);
    		opacity: 0;
    	}
    }
    @keyframes move_4 {
    	0% {
    		transform: translateY(-110%);
    	}
    	73% {
    		transform: translateY(-110%);
    	}
    	75% {
    		transform: translateY(0%);
    	}
    	97% {
    		transform: translateY(0%);
    	}
    	98% {
            transform: translateY(110%);
            opacity: 1;
    	}
    	99% {
    		transform: translateY(110%);
            opacity: 0;
    	}
    	100% {
    		transform: translateY(-110%);
    		opacity: 0;
    	}
    }
}
@media screen and (max-width: 800px){
    
}
@media screen and (max-width: 600px){
	.m_2_wrapper {
		font-size: 12px;
		line-height: 24px;
	}
	.m_2_dop_wrapper {
		font-size: 22px;
	}
}
@media screen and (max-width: 500px){

}
@media screen and (max-width: 374px){
    .m_2_dop_wrapper {
		font-size: 18px;
	}
}