@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-Regular.eot");
    src: url("../fonts/opensans/OpenSans-Regular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/opensans/OpenSans-Regular.woff") format("woff"),
    url("../fonts/opensans/OpenSans-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-Light.eot");
    src: url("../fonts/opensans/OpenSans-Light.eot?#iefix")format("embedded-opentype"),
    url("../fonts/opensans/OpenSans-Light.woff") format("woff"),
    url("../fonts/opensans/OpenSans-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-SemiBold.eot");
    src: url("../fonts/opensans/OpenSans-SemiBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/opensans/OpenSans-SemiBold.woff") format("woff"),
    url("../fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/opensans/OpenSans-Bold.eot");
    src: url("../fonts/opensans/OpenSans-Bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/opensans/OpenSans-Bold.woff") format("woff"),
    url("../fonts/opensans/OpenSans-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: "Lobster";
    src: url("../fonts/lobster/Lobster.eot");
    src: url("../fonts/lobster/Lobster.eot?#iefix")format("embedded-opentype"),
    url("../fonts/lobster/Lobster.woff") format("woff"),
    url("../fonts/lobster/Lobster.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}