.m_3 {
	padding-top: 50px;
}
.main_h2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: "Lobster";
	font-style: normal;
	font-weight: normal;
	font-size: 40px;
	line-height: 40px;
	color: #0C990D;
	text-align: center;
	margin-bottom: 50px;
	.main_h2_icon {
		margin-top: 15px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 72.79px;
		height: 36.39px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.m_3_flex {
	display: flex;
	margin-left: -35px;
	margin-right: -35px;
	margin-top: -35px;
}
.m_3_el {
	width: calc(25% - 70px);
	margin-left: 35px;
	margin-right: 35px;
	margin-top: 35px;
}
.m_3_el_wrapper {
	width: 100%;
	a {
		display: block;
		width: 100%;
	}
}
.m_3_el_image {
	width: 100%;
	height: 365px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.m_3_but {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}



.slick-next, .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    display: block;
    padding: 0px;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
    &:hover {
        box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
        opacity: 1;
    }
}
.slick-prev {
    z-index: 99;
    left: 0px;
    background-image: url("../img/slick_prev.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.slick-next {
    right: 0px;
    background-image: url("../img/slick_next.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.m_3_flex_sider_mob {
    width: 100%;
    position: relative;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    .m_3_el {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(25% - 20px);
        margin-top: 20px;
        width: 257px;
        max-width: 100%;
    }
}
@media screen and (max-width: 1300px){
    .m_3_el {
    	margin-left: 10px;
    	margin-right: 10px;
    	width: calc(25% - 20px);
    	margin-top: 20px;
    }
    .m_3_flex {
    	margin-top: -20px;
    	margin-left: -10px;
    	margin-right: -10px;
    }
}
@media screen and (max-width: 992px){
    .m_3_el {
    	width: 257px;
    	max-width: 100%;
    }
    .m_3_flex {
    	margin-left: auto;
    	margin-right: auto;
    	width: 600px;
    	max-width: 100%;
    	justify-content: center;
    	flex-wrap: wrap;
    }
    .m_3_flex_sider_mob  {
    	width:100%;
    	position: relative;
    	left: 0px;
    	.slick-list {
    		padding-right: 50px;
    	}
    }
    .m_3_mob_slider {
    	.wrapper {
    		padding: 0px;
    	}
    }
    .m_3_mob_slider .m_3_but {
    	padding-left: 10px;
    	padding-right: 10px;
    }
    
}
@media screen and (max-width: 800px){
    
}
@media screen and (max-width: 600px){
    .m_3_el {
    	margin-top: 30px;
    }
    .main_h2 {
    	font-size: 26.4922px;
    	line-height: 26px;
    	margin-bottom: 30px;
    }
    .m_3_el_image {
    	height: 320px;
    }
}
@media screen and (max-width: 500px){
	.m_3_el_image {
    	height: 260px;
    }
}
@media screen and (max-width: 480px){
    .m_3_el_image {
    	height: 320px;
    }
    .m_3_flex_sider_mob {
    	.slick-list {
    		padding-right: 160px;
    	}
    }
    .m_3_flex_sider_mob .slick-slide{
    	transition: 0.25s;
    	opacity: 0.6;
    }
    .m_3_flex_sider_mob .slick-current {
    	opacity: 1;
    }
}
@media screen and (max-width: 420px){
    .m_3_flex_sider_mob {
    	.slick-list {
    		padding-right: 100px;
    	}
    }
}