@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
}

/* Icons */
@font-face
{
 
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    width: 24px;
    height: 16px;
    display: block;

    padding: 20px 40px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    opacity: 0.8;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    z-index: 99;
    left: -5%;
    background-image: url(../../img/prev.png);
    background-position: center;
    background-repeat: no-repeat;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '';
}
[dir='rtl'] .slick-prev:before
{
    content: '';
}

.slick-next {
    right: -5%;
    background-image: url(../../img/next.png);
    background-position: center;
    background-repeat: no-repeat;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '';
}
[dir='rtl'] .slick-next:before
{
    content: '';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: 25px;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 6px;
    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

        width: 50px;
    height: 6px;
    background: #000000;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: flex;

    width: 50px;
    height: 6px;
    background: #000000;
    padding: 0px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    background: silver;
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    background: silver;
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 50px;
    height: 6px;

    content: '';
    text-align: center;

    opacity: .25;
    color: silver;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: silver;
}
.slick-active .slick-dots{
    background: silver;
    padding: 0;
    margin: 0;
}