@import "reload.scss";
@import "fonts.scss";
/* Библиотеки */
@import "../library/css_gamburgers/hamburgers.min.css";
@import "../library/animateCSS/animate.css";
@import "../library/fancybox/jquery.fancybox.min.css";
@import "../library/jQuerryFormStyler/jquery.formstyler.css";
@import "../library/jQuerryFormStyler/jquery.formstyler.theme.css";
@import "../library/remodal/remodal.css";
@import "../library/remodal/remodal-default-theme.css";
@import "../library/slick/slick.css";
@import "../library/slick/slick-theme.css";
/* Библиотеки end*/
   
body {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.main_wrapper {
    width: 100%;
    overflow: hidden;
}
.section {
    position: relative;
}
.wrapper {
    width: 1300px;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
}
.page_container {
    padding-top: 140px;
}
.main_but {
    display: block;
    background: linear-gradient(180deg, #DD433F 0%, #DD433F 100%);
    border: 2px solid #0C990D;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), inset 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    width: 300px;
    max-width: 100%;
    line-height: 51px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    position: relative;
    text-align: center;
    &:before {
        content: '';
        display: block;
        background-image: url('../img/main_but_before.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 31.28px;
        height: 39.81px;
        position: absolute;
        transform-origin: center;
        transition: 0.25s;
        top: 6px;
        left: 11px;
        display: none;
    }
    &:after {
        content: '';
        display: block;
        background-image: url('../img/main_but_after.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 31.28px;
        height: 39.81px;
        transform-origin: center;
        transition: 0.25s;
        position: absolute;
        top: 6px;
        right: 11px;
        display: none;
    }
    &:hover {
        border: 2px solid #DD433F;
        &:before {
            transform: scale(1.2);
        }
        &:after {
            transform: scale(1.2);
        }
    }

}
@media screen and (max-width: 992px){
    .wrapper {
        padding-left: 10px;
        padding-right: 10px;
    }
    .page_container {
        padding-top: 80px;
    }
}

@import "header.scss";
@import "m_1.scss";
@import "m_2.scss";
@import "m_3.scss";
@import "main_page.scss";







@media screen and (max-width: 1300px){
    
}
@media screen and (max-width: 992px){
    
}
@media screen and (max-width: 800px){
    
}
@media screen and (max-width: 600px){
    
}
@media screen and (max-width: 500px){

}
@media screen and (max-width: 374px){
    
}











































/* 
Модальное окно с формой




.remodal_2 {
    width: 409px;
    padding: 19px;
    max-width: 100%;
    background: #fff;
}
.remodal_wrapper_2 {
    background: rgba(196, 196, 196, 0.1);
    padding: 40px 20px;
}
.remodal-close {
    width: 25px;
    line-height: 25px;
    left: auto;
    right: 0;
}
.remodal_wrapper_2 h2 {

    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: normal;
    font-family: "Century Gothic";
    text-align: center;
    color: #E14B4C;
    margin-bottom: 20px;
}
.remodal_wrapper_2 p {
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #1A1A1A;
    margin-bottom: 20px;
}
.remodal_wrapper_2 input {
    background: #FFFFFF;
    display: block;
    width: 260px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    background: rgba(253, 253, 253, 0.5);
border: 1px solid #000000;
box-sizing: border-box;
    color: #959595;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}
.remodal_wrapper_2 button {
    background: #C8102E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    line-height: 40px;
    width: 260px;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 44px;
    color: #E14B4C;
    border: 2px solid #E14B4C;
    background: #fff;
}
.remodal_wrapper_2 button:hover {
    background: #E14B4C;
    color: #fff;
}
.remodal_2 .remodal-close:before {
    width: 25px;
    line-height: 25px;
}
.remodal_2 .remodal-close {
    width: 25px;
    line-height: 25px;
} 


*/


 

.remodal_thanks {
    width: 430px;
    max-width: 100%;
}
.remodal_thanks h2 {
    text-align: center;
    margin-bottom: 0;
    font-size: 25px;
}
.remodal_thanks p {
    text-align: center;
}
.remodal_thanks .remodal_wrapper_2 {
    padding: 20px 10px 20px 10px;
}
@media screen and (max-width: 600px){
    .remodal_thanks .remodal_wrapper_2 h2 {
        font-size: 20px;
        text-align: center;
    }
    .remodal_wrapper_2 p {
        font-size: 16px;
        text-align: center;
    }
} 









/* 
    Пагинация вордпресс, через плагин WP-PageNavi




.b_1_paginator {
    margin-top: 20px;
    margin-bottom: 60px;
}
.wp-pagenavi .current {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 30px;
    width: 32px;
    font-size: 15px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    color: #000000;
    border: 1px solid transparent;
    background: #A7046F;
    border: 1px solid #A7046F;
    border-radius: 10px;
    display: block;
    color: #fff;
}
.wp-pagenavi a {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 30px;
    width: 32px;
    font-size: 15px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    color: #000000;
    border: 1px solid transparent;
}
.wp-pagenavi a:hover {
    border: 1px solid #A7046F;
}
.wp-pagenavi .nextpostslink {
    background-image: url(../img/k_1_pagination_right.svg);
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 30px;
    width: 32px;
    font-size: 15px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    color: transparent;
    border: 1px solid transparent;
}
.wp-pagenavi .nextpostslink:hover {
    background: #DADADA !important;
border-radius: 10px !important;
border: 1px solid transparent !important;
    background-image: url(../img/k_1_pagination_right.svg) !important;
    background-position: center !important; 
    background-repeat: no-repeat !important;
}
.wp-pagenavi .previouspostslink:hover {
        background: #DADADA !important;
border-radius: 10px !important;
border: 1px solid transparent !important;
    background-image: url(../img/k_1_pagination_left.svg) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}
.wp-pagenavi .previouspostslink {
    background-image: url(../img/k_1_pagination_left.svg);
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 30px;
    width: 32px;
    color: transparent;
    font-size: 15px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
}
.wp-pagenavi {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pages {
    display: none;
}
 */













