header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 300;
	padding: 20px 0px;
}
.header_wrapper {
	background: linear-gradient(180deg, #C52333 0%, #A40918 100%);
	border-radius: 10000px;
	padding: 20px 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.header_left {
	position: absolute;
	left: -5px;
	top: 50%;
	width: 95px;
	height: 95px;
	border-radius: 100%;
	transform: translateY(-50%);
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
		img {
			max-width: 100%;
			transition: 0.25s;
			transform-origin: center;
			max-height: 100%;
		}
	}
}
.main_menu {
	ul {
		display: flex;
		justify-content: center;
		align-items: center;
		li {
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				font-style: normal;
				font-weight: 600;
				font-size: 15px;
				line-height: 26px;
				text-transform: uppercase;
				color: #FFFFFF;
				margin-left: 25px;
				margin-right: 25px;
				&:hover {
					&:before {
						transform: scale(1.2);
					}
				}
				&:before {
					content: '';
					display: block;
					width: 20px;
					min-width: 20px;
					height: 20px;
					margin-right: 10px;
					transform-origin: center;
					transition: 0.25s;
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
		}
		li:nth-child(1){
			a {
				&:before {
					background-image: url('../img/header_icon_1.svg');
				}
			}
		}
		li:nth-child(2){
			a {
				&:before {
					background-image: url('../img/header_icon_2.svg');
				}
			}
		}
		li:nth-child(3){
			a {
				&:before {
					width: 26px;
					min-width: 26px;
					height: 26px;
					background-image: url('../img/header_icon_3.svg');
				}
			}
		}
		li:nth-child(4){
			a {
				&:before {
					background-image: url('../img/header_icon_4.svg');
				}
			}
		}
		li:nth-child(5){
			a {
				&:before {
					background-image: url('../img/header_icon_5.svg');
				}
			}
		}
		li:nth-child(6){
			a {
				&:before {
					background-image: url('../img/header_icon_6.svg');
				}
			}
		}
	}
}
.header_right {
	position: absolute;
	right: -5px;
	top: 50%;
	width: 95px;
	height: 95px;
	border-radius: 100%;
	transform: translateY(-50%);
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		&:hover {
			transform: scale(1.1);
		}
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.header_top_mobile {
	display: none;
}
.mobile_nav {
	display: none;
}
@media screen and (max-width: 1300px){
    .main_menu ul li a {
    	margin-left: 20px;
    	margin-right: 20px;
    }
}
@media screen and (max-width: 1200px){
    .main_menu ul li a {
    	margin-left: 12px;
    	margin-right: 12px;
    }
    .main_menu ul li a {
    	font-size: 13px;
    }
}
@media screen and (max-width: 992px){
    .header_wrapper {
    	display: none;
    }
    header {
    	background: linear-gradient(180deg, #C52333 0%, #A40918 100%);
    	padding: 5px 0px;
    }
    .header_top_mobile {
    	display: flex;
    	justify-content: space-between;
    	align-items: center;
    	position: relative;
    }
    .header_mobile_logo {
    	width: 50px;
    	height: 50px;
    	display: flex;
    	justify-content: center;
    	align-items: center;
    	img {
    		width: 100%;
    		height: 100%;
    		max-width: 100%;
    		max-height: 100%;
    	}
    }
    .header_mobile_contact_but{
    	width: 30px;
    	height: 30px;
    	background-position: center;
    	display: block;
    	background-repeat: no-repeat;
    	background-size: contain;
    }
    .hamburger {
    	padding: 0px;
    	position: relative;
    	top: 1px;
    }
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    	background: #fff !important;
    	height: 3px;
    }
    .hamburger-inner:before {
    	width: 30px;
    	right: 0px;
    	top: -10.5px;
    }
    .hamburger.is-active {
    	.hamburger-inner:before {
    		width: 40px;
    	}
    }
    .header_mob_item {
    	width: 100px;
    }
    .header_mob_item_center {
    	display: flex;
    	justify-content: center;
    }
    .header_mob_item_right {
    	display: flex;
    	justify-content: flex-end;
    }
    .mobile_nav {
    	display: none;
    	padding-top: 40px;
    	padding-bottom: 30px;
    }
    .mobile_nav.active {
    	display: block;
    }
    .main_menu {
    	width: 100%;
    	padding-left: 30px;
    	padding-right: 30px;
    	max-height: 100%;
    	margin-left: auto;
    	margin-right: auto;
    	ul {
    		flex-direction: column;
    		justify-content: flex-start;
    		align-items: flex-start;
    		li {
    			a {
    				margin-left: 0px;
    				margin-right: 0px;
    				margin-bottom: 30px;
    				&:before {
    					width: 25px !important;
    					min-width: 25px !important;
    					height: 25px !important; 
    				}
    			}
    			&:last-child {
    				a {
    					margin-bottom: 0px;
    				}
    			}
    		}
    	}
    }
}
@media screen and (max-width: 800px){
    
}
@media screen and (max-width: 600px){
    
}
@media screen and (max-width: 500px){

}
@media screen and (max-width: 374px){
    
}