@charset "UTF-8";code,kbd,pre,samp,tt,var{font:14px/18px Consolas,"Courier New",monospace}body,optgroup{color:#000;background:#fff}body,code,dl,dd,form,pre{margin:0}a:link,a:visited,ins{text-decoration:none}a:hover{text-decoration:underline}a:link img,a:visited img,object,fieldset,abbr,acronym{border:none}sub,sup{position:relative;display:inline-block;font-size:11px;line-height:11px}sub{top:5px;vertical-align:middle}sup{top:-2px;margin-top:-11px}abbr,acronym{cursor:help;border-bottom:1px dotted #999}address,cite,dfn{font-style:normal}code,pre{white-space:pre-wrap}img{vertical-align:bottom}td,th,textarea{vertical-align:top}input,select,button{overflow:visible;vertical-align:middle}legend{color:#000;margin:0 0 -2px;padding:0}li{display:block;list-style:none}optgroup option{padding-left:22px}table{border-collapse:collapse}caption,th{text-align:left}td,th{padding:0}address,blockquote,h1,h2,h3,h4,h5,h6,fieldset,p,ol,ul{margin:0;padding:0}th,h1,h2,h3,h4,h5,h6{font-weight:400}q{quotes:"\00ab" "\00bb" "\201e" "\201c"}q:after{content:"\00bb"}q:before{content:"\00ab"}q > q:after{content:"\201c"}q > q:before{content:"\201e"}.hamburger{font:inherit;display:inline-block;overflow:visible;margin:0;padding:15px;cursor:pointer;transition-timing-function:linear;transition-duration:.15s;transition-property:opacity,filter;text-transform:none;color:inherit;border:0;background-color:transparent}.hamburger:hover{opacity:.7}
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     border-radius: 0;
     -webkit-appearance: none;
}
input {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
/*end*/
/*Стили сбросса*/
button {
	border: none;
	display: block;
    cursor: pointer;
    transition: 0.25s;
}
*{
	box-sizing: border-box;
    outline: none;
}
input::placeholder {
	padding: 0;
}
input {
	outline: none;
	border: none;
}
a {
	text-decoration: none;
	transition: 0.25s;
}
textarea {
	resize: none;
	outline: none;
}
a:hover {
	text-decoration: none;
}
a, .hamburger, button  {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/*конец стилей сбросса*/
input.error {
    border: 1px solid red !important;
}
label.error {
    display: none !important;
}
body {
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
}

.wpcf7-form br{
    display: none !important;
}
span.wpcf7-not-valid-tip {
    display: none !important; 
}
div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    display: none !important; 
}
input.wpcf7-not-valid {
    border: 1px solid red !important;
}
div.wpcf7-mail-sent-ok {
    display: none !important; 
}
div.wpcf7 .ajax-loader {
    display: none !important; 
}
.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
    display: none !important; 
}
.wpcf7 form.sent .wpcf7-response-output {
    display: none !important; 
}
.wpcf7 form .wpcf7-response-output, .wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
	display: none !important;
}
.page_container {
    width: 100%;
    overflow: hidden; 
}