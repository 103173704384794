.m_4 {
	padding-top: 50px;
}
#map {
	width: 100%;
	height: 460px;
	border-radius: 20px;
	position: relative;
	z-index: 30;
}
.m_4_contacts {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background: #0C990D;
	border-radius: 5px;
	margin-top: 15px;
	padding: 15px 0px;
	padding-bottom: 0px;
}
.m_4_block {
	width: 250px;
	margin-left: 60px;
	margin-right: 60px;
	margin-bottom: 15px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: #FFFFFF;
	a {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		color: #FFFFFF;
		&:hover {
			opacity: 0.8;
		}
	}
}
.m_5 {
	padding-top: 50px;
}
.m_5_flex {
	display: flex;
	margin-left: -25px;
	margin-right: -25px;
	margin-top: -30px;
}
.m_5_el {
	margin-top: 30px;
	margin-left: 25px;
	margin-right: 25px;
	width: calc(33.333% - 50px);
}
.m_5_name {
	font-family: "Lobster";
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	line-height: 40px;
	color: #C52333;
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		margin-top: 25px;
		background: linear-gradient(90deg, #C52434 0%, #FDF8F9 100%);
		margin-bottom: 25px;
	}
}
.m_5_text {
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 26px;
	color: #000000;
}

footer {
	background: #FCFCFC;
	padding: 10px 0px;
	margin-top: 60px;
}
.footer_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #868484;
}





.p_1 {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
}
.p_1_h1 {
	width: 100%;
	z-index: 40;
	position: relative;
}
.p_1_wrapper {
	min-height: 320px;
	display: flex;
	position: relative;
	align-items: center;
	h1 {
		font-family: "Lobster";
		font-style: normal;
		font-weight: normal;
		font-size: 55.0892px;
		line-height: 78px;
		letter-spacing: 0.01em;
		color: #0C990D;
		position: relative;
		&:before {
			content: '';
			display: block;
			background: #FFFFFF;
			width: 140px;
			height: 140px;
			border-radius: 100%;
			position: absolute;
			left: 0px;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 10;
			animation: m_1_round_top_2 4s linear 0s infinite;
		}
		&:after {
			content: '';
			display: block;
			background: #FFFFFF;
			opacity: 0.6;
			width: 170px;
			height: 170px;
			border-radius: 100%;
			position: absolute;
			left: 0px;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 7;
			animation: m_1_round_top_2 4s linear 0s infinite;
		}
		span {
			position: relative;
			z-index: 20;
		}
	}
}
.p_1_composition_image {
	position: absolute;
	bottom: -5px;
	right: 10%;
	width: 400px;
	height: 280px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	&:before {
		content:'';
		display: block;
		background: #0C990D;
		opacity: 0.7;
		border-radius: 1000px;
		width: 400px;
		height: 400px;
		position: absolute;
		left: 50%;
		top: 15px;
		z-index: 10;
		transform-origin: center;
		transform: translateX(-50%);

	}
	&:after {
		content: '';
		display: block;
		background: #0C990D;
		border-radius: 1000px;
		width: 338.68px;
		height: 338.68px;
		position: absolute;
		left: 50%;
		z-index: 20;
		top: 50px;
		transform-origin: center;
		transform: translateX(-50%);
	}
}
@keyframes m_1_round_top_1 {
	0% {
		transform: translateX(-50%) scale(0.5);
		opacity: 1;
	}
	100% {
		transform: translateX(-50%) scale(1.2);
		opacity: 0;
	}
}
@keyframes m_1_round_top_2 {
	0% {
		transform:  translate(-50%, -50%) scale(0.5);
		opacity: 1;
	}
	90% {
		transform:  translate(-50%, -50%) scale(1.1);
		opacity: 1;
	}
	100% {
		transform:  translate(-50%, -50%) scale(1.2);
		opacity: 0;
	}
}
.p_1_composition_image_bg {
	position: relative;
	z-index: 30;
	width: 100%;
	height: 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
	
}
.m_3_flex {
	flex-wrap: wrap;
}
.ap_1 {
	padding-top: 40px;
}


form {
	input {
		border: 1.22467px solid #DFDFDF;
		box-sizing: border-box;
		border-radius: 4.8987px;
		width: 500px;
		font-family: "Open Sans";
		max-width: 100%;
		display: block;
		margin-bottom: 15px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 15px;
		color: #000000;
		padding: 13px 18px;
	}
	textarea {
		border: 1.22467px solid #DFDFDF;
		box-sizing: border-box;
		border-radius: 4.8987px;
		width: 500px;
		font-family: "Open Sans";
		max-width: 100%;
		display: block;
		margin-bottom: 15px;
		font-style: normal;
		height: 200px;
		font-weight: normal;
		font-size: 16px;
		line-height: 15px;
		color: #000000;
		padding: 13px 18px;
	}

}
.jq-file {
	width: 500px;
	max-width: 100%;
	box-shadow: none  !important;;
	cursor: pointer;
	border: none  !important;
	outline: none !important;
}
.jq-file__browse {
	right: auto;
	left: 0px;
	width: 45px;
	height: 45px;
	background-image: url('../img/input_dowload.svg') !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	border-radius: 100%;
	top: 50%;
	font-size: 0px;
	border: none  !important;;
	box-shadow: none  !important;;
	color: transparent;
	transform: translateY(-50%);
}
.jq-file__name {
	padding-left: 60px;
	padding-right: 5px;
	border: none !important;
	box-shadow: none !important;
	height: 50px;
	font-family: "Open Sans";
	line-height: 50px;
	font-size: 16px;
	color: #000000;
}
.my_checkbox_class {
	font-style: normal;
	font-weight: normal;
	font-size: 12.2467px;
	line-height: 15px;
	color: #000000;
	label {
		display: flex;
		align-items: center;
	}
}
.jq-checkbox, .jq-radio {
	border: 1px solid #DFDFDF !important;
	box-sizing: border-box;
	border-radius: 1224.67px;
	width: 25px;
	min-width: 25px;
	margin-right: 15px;
	height: 25px;
	outline: none;
}
.jq-checkbox.checked .jq-checkbox__div {
	width: 17px;
	height: 17px;
	background: #C52333;
	top: 4px;
	left: 4px;
	border-radius: 1224.67px;
	border: none !important;
}
.my_checkbox_class {
	margin-top: 20px;
	width: 500px;
	max-width: 100%;
	margin-bottom: 40px;
	button {
		margin-left: auto;
		margin-right: auto;
	}
}
.ap_1 {
	padding-bottom: 10px;
}

.ap_form {
	background: #FFFFFF;
	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 100%;
	}
}
.jq-selectbox {
	width: 500px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;
}
.jq-selectbox__trigger{
	width: 50px;
	height: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
	transform: none;
	background-image: url('../img/select_arrow.svg');
	background-position: center;
	background-repeat: no-repeat;
}
.jq-selectbox__trigger-arrow {
	display: none;
}
.jq-selectbox__select {
	border: 1.22531px solid #DFDFDF;
	box-sizing: border-box;
	border-radius: 4.90126px;
	position: relative;
}
.jq-selectbox__select-text {
	border-radius: 4.8987px;
	width: 500px;
	font-family: "Open Sans";
	max-width: 100%;
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 15px;
	color: #000000;
	padding: 13px 18px;
	padding-right: 50px;
}
.p_1_composition_image_contain .p_1_composition_image_bg{
	background-size: contain;
}
.p_1_composition_carier .p_1_composition_image_bg{
	left: -115px;
}
.ap_2 {
	padding-top: 40px;
}
.ap_2_block {
	margin-bottom: 35px;
	cursor: pointer;
	background: #FFFFFF;
box-shadow: 0px 0px 10.3659px rgba(0, 0, 0, 0.05);
}
.ap_2_block_name {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px;
	padding-right: 0px;
}
.ap_2_block_name_arrow {
	width: 50px;
	transition: 0.25s;
	height: 20px;
	min-width: 50px;
	margin-left: 20px;
	background-position: center;
	background-repeat: no-repeat;
	transform-origin: center;
}
.ap_2_block_name_text {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 17px;
	color: #0C990D;
}
.ap_2_text {
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 26px;
	padding: 0px 30px;
	padding-bottom: 30px;
	color: #000000;
	display: none;
}
.ap_2_block.active {
	.ap_2_text {
		display: block;
	}
	.ap_2_block_name_arrow {
		transform: rotate(180deg);
	}
}
.header_mobile_logo img {
	border-radius: 100%;
}
@media screen and (max-width: 1300px){
    
}
@media screen and (max-width: 992px){
    .m_4_contacts {
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
    	text-align: center;
    	width: calc(100% - 20px);
    	margin-left: auto;
    	margin-right: auto;
    }
    .m_4_block {
    	margin-left: auto;
    	margin-right: auto;
    }
    .m_4  {
    	.wrapper {
    		padding: 0px;
    	}
    }
    #map {
    	border-radius: 0px;
    	height: 445px;
    }
    .m_5_flex {
    	flex-direction: column;
    	margin-left: auto;
    	margin-right: auto;
    }
    .m_5_el {
    	width: 100%;
    	margin-left: auto;
    	margin-right: auto;
    }
    .p_1_composition_image {
    	right: auto;
    	left: 50%;
    	transform: translateX(-90%);
    }
    .p_1_h1 {
    	justify-content: center;
    	display: flex;
    }
    .p_1_wrapper h1 {
    	position: relative;
    	text-align: center;
    }
    .p_1_composition_image:before {
    	background: #fff;
    }
    .p_1_composition_image:after {
    	background: #fff;
    }
    .p_1_wrapper h1:before {
    	animation: none;
    	left: 60px
    }
    .p_1_wrapper h1:after {
    	animation: none;
    	left: 60px;
    }
    .p_1_composition_image_center {
    	left: 50%;
    	transform: translateX(-50%);
    	width: calc(100% + 40px);
    }
    .p_1_composition_carier .p_1_composition_image_bg{
    	left: 200px;
    	width: 250px;
    	height: 150px;
    	background-size: contain;
    }
}
@media screen and (max-width: 800px){
    
}
@media screen and (max-width: 600px){
	.ap_1_wrapper {
		box-shadow: 0px 0px 7.41499px rgba(0, 0, 0, 0.05);
		padding: 30px 20px;
		width: 540px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
    .m_5_name:after {
    	margin-top: 15px;
    	margin-bottom: 15px;
    }
    .p_1_wrapper h1 {
    	font-size: 39.4251px;
    	line-height: 56px;
    }
    .p_1_composition_image {
    	transform: translateX(-70%);
    }
    .p_1_composition_image_center {
    	left: 50%;
    	transform: translateX(-50%);
    	.p_1_composition_image_bg {
    		width: 100%;
    	}
    }
    .ap_1_wrapper {
    	padding: 30px 10px;
    }
    .p_1_composition_image_contain {
    	left: auto;
    	right: 0px;
    	transform: none;
    	width: 250px;
    }
    .p_1_composition_carier .p_1_composition_image_bg {
    	left: 0px;
    }
    .p_1_composition_carier  {
    	left: auto;
    	right: -90px;
    	transform: none;
    }
    .ap_2_text {
    	padding-left: 10px;
    	padding-right: 10px;
    	padding-bottom: 20px;
    }
    .ap_2_block_name {
    	padding-top: 20px;
    	padding-bottom: 20px;
    	padding-left: 10px;
    }
    .ap_2_block {
    	margin-bottom: 25px;
    }
}
@media screen and (max-width: 500px){
	.footer_wrapper {
		font-size: 10px;
	}
	.p_1_composition_image_center {
    	.p_1_composition_image_bg {
    		background-size: cover;
    	}
    }
}
@media screen and (max-width: 374px){
    
}
