

.m_1_slaid_wrapper {
	width: 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 420px;
	padding: 50px 0px;
}
.m_1_composition {
	width: 580px;
	max-width: 100%;
	position: relative;
	z-index: 20;
}
.m_1_h1 {
	font-family: "Lobster";
	font-style: normal;
	font-weight: normal;
	font-size: 56px;
	line-height: 66px;
	margin-bottom: 30px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #0C990D;
}
.m_1_text {
	display: flex;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 35px;
	color: #C52333;
	.icon {
		width: 30px;
		min-width: 30px;
		margin-right: 15px;
		height: 35px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}
.slick-dots li button {
	display: none;
}
.slick-dotted.slick-slider {
	margin-bottom: 0px;
}
.slick-dots li  {
	width: 65px;
	height: 3px;
	background: #767676;
	margin-left: 12px;
	margin-right: 12px;
	transition: 0.25s;
	transform-origin: center;
	&:hover {
		transform: scale(1.1);
	}
	&.slick-active {
		background: #C52333;
		transform: scale(1) !important;
	}
}
.m_1_slaid_bg {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
}
@media screen and (max-width: 1200px){
    .m_1_composition {
    	width: 480px;
    }
    .m_1_h1 {
    	font-size: 48px;
    	line-height: 58px;
    }
    
}
@media screen and (max-width: 992px){
    .m_1_composition {
    	margin-left: auto;
    	margin-right: auto;
    	margin-bottom: 30px;
    }
    .m_1_slaid_bg {
    	position: static;
    	height: 290px;
    	background-position: right bottom;
    }
    .m_1_slaid_wrapper {
    	padding: 0px;
    }
    .m_1_slaid_wrapper {
    	min-height: 0px;
    }
    .slick-dots {
    	bottom: -20px;
    }
}
@media screen and (max-width: 800px){
    .m_1_slaid_bg {
    	height: 220px;
    }
}
@media screen and (max-width: 600px){
    .m_1_h1 {
    	font-size: 32px;
    	line-height: 42px;
    	margin-bottom: 15px;
    }
    .m_1_text .icon {
    	width: 20px;
    	min-width: 20px;
    	height: 20px;
    	margin-right: 12px;
    }
    .m_1_text {
    	font-size: 16px;
    	line-height: 20px;
    }
    .slick-dots li {
    	width: 45px;
    	height: 2px;
    	margin-left: 5px;
    	margin-right: 5px;
    }
}
@media screen and (max-width: 500px){

}
@media screen and (max-width: 374px){
    .m_1_h1 {
    	font-size: 28px;
    }
    .m_1_slaid_bg {
    	height: 180px;
    }
}